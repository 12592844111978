import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import verwarming from '../images/verwarming/verwarming_stookolie.jpg'
import warmtepomp from '../images/verwarming/verwarming_warmtepomp.jpg'
import zonneInstallatie from '../images/verwarming/verwarming_zonneinstallatie.jpg'
import radiatoren from '../images/verwarming/verwarming_radiator.jpg'
import cerga from '../images/verwarming/cerga.jpg'
import rescert from '../images/verwarming/rescert.jpg'

const VerwarmingPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
        `merken`,
        `diensten`,
        `verwarming`,
        `Viesmann`,
        `Vasco`,
        `Henrad`,
        `Begetube`,
        `Cerga`,
        `Rescert`,
        `erkend`,
      ]}
    />
    <h1
      style={{
        marginBottom: `0`,
        paddingBottom: `0.2rem`,
      }}
    >
      Verwarming
    </h1>

    <div className="verwarming__item">
      <h2>Gas of stookolie</h2>
      <div className="verwarming__item__content">
        <img
          src={verwarming}
          alt="foto stookolie"
          className="verwarming__image"
        />
        <div className="verwarming__item__list">
          <p>
            <strong>Diensten: </strong>Onderhoud - herstelling - nieuwe
            installatie{' '}
          </p>
          <p>
            <strong>Merken: </strong> Viessmann - Bulex
          </p>
        </div>
      </div>
    </div>

    <div className="verwarming__item">
      <h2>Warmtepomp</h2>
      <div className="verwarming__item__content">
        <img
          src={warmtepomp}
          alt="foto warmtepomp"
          className="verwarming__image"
        />
        <div className="verwarming__item__list">
          {/* <p><strong>Diensten: </strong>Onderhoud - herstelling - nieuwe installatie </p> */}
          <p>
            <strong>Merken: </strong> Viessmann{' '}
          </p>
        </div>
      </div>
    </div>

    <div className="verwarming__item">
      <h2>Zonne-installatie</h2>
      <div className="verwarming__item__content">
        <img
          src={zonneInstallatie}
          alt="foto zonne-installatie"
          className="verwarming__image"
        />
        <div className="verwarming__item__list">
          <p>
            <strong>Merken: </strong> Viessmann
          </p>
        </div>
      </div>
    </div>

    <div className="verwarming__item">
      <h2>Radiatoren - Vloerverwarming</h2>
      <div className="verwarming__item__content">
        <img
          src={radiatoren}
          alt="foto radiator"
          className="verwarming__image"
        />
        <div className="verwarming__item__list">
          <p>
            <strong>Merken: </strong>Vasco - Henrad - Begetube
          </p>
        </div>
      </div>
    </div>

    <div className="verwarming__item">
      <h2>Erkend Cerga installateur</h2>
      <div className="verwarming__item__content">
        <img
          src={cerga}
          alt="foto cerga certificaat"
          className="verwarming__image_large"
        />
      </div>
    </div>

    <div className="verwarming__item">
      <h2>
        Rescert certificaat warmptepomp en thermische zonne-energie voor SWW
      </h2>
      <div className="verwarming__item__content">
        <a
          href="https://www.rescert.be/nl/certificats-possibles"
          target="blank"
          rel="noopener noreferrer"
        >
          <img
            src={rescert}
            alt="foto rescert certificaat"
            className="verwarming__image_large"
            style={{
              height: `8rem`,
            }}
          />
        </a>
      </div>
    </div>
  </Layout>
)

export default VerwarmingPage
